import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../components/common'

const AboutPage = () => {

    const pageInfo = {
        title: "About",
        byline: "Your one stop blog for all things technical writing in the software industry."
    }

    return (
        <Layout isPage={true} pageInfo={pageInfo}>
            <div className="aboutpage">
                <div className="aboutpage-image">
                    <img src="images/headshot-small.jpeg"></img>
                </div>
                <div className="aboutpage-content">
                    <h2>Hello, my name is <a href="https://lindaikechukwu.com/">Linda Ikechukwu</a> </h2>

                    <p>I am the human behind everythingtechnicalwriting.com.</p>

                    <p>
                        I've spent 6 years of my career working in various facets of IT; 
                        from cloud engineering, to frontend development and now developer educator
                         where I’m obsessed with lowering the learning curve for complex developer 
                         tools to aid accelerated developer adoption.
                    </p>


                    <h2>Why did I start this blog?</h2>
                    <p>
                        While transitioning from frontend developer to technical writer in the software industry, I discovered two things:
                    </p>
                    <ul>
                        <li>There wasn't much awareness that technical writing is a viable and lucrative career path, just like programming is, especially in the African continent.</li>
                        <li>There was a deficit of exhaustive resources on the techniques and intricacies of technical writing as a career in the software industry. </li>
                    </ul>
                    <p> I started this website to contribute my quota to fixing both narratives. With everythingtechnicalwriting.com, I will: </p>
                    <ul>
                        <li>Raise awareness on the viability and lucrative nature of various technical writing careers.</li>
                        <li>Write <b>articles that detail my learnings and experience as a technical communicator</b> to build an exhaustive technical writing resource bank.</li>
                        <li>Bring you <b>interviews with seasoned technical communicators</b> on how they got their start and overcame obstacles in their careers, so you can stand on the shoulders of giants as you navigate your own career</li>
                        <li>Help you <b>become a better and sought-after technical communicator</b>.</li>
                    </ul>
                    <p>So, if you're thinking of making a transition to become a technical writer, or you want to skill up in technical writing, then you're at the right place. </p>
                    <p>Browse through articles on the site, and <a className="gradient-text" href="https://everythingtechnicalwriting.substack.com/about">SUBSCRIBE TO THE NEWSLETTER↓</a> for valuable career tips and curated remote technical writing jobs. </p>
                    <p>If you love what I do and would like to support me, <a className="gradient-text" href="https://www.buymeacoffee.com/lindaikechukwu">BUY ME A COFFEE</a></p>
                    <p>And if you want to partner in terms of sponsorships for the newsletter or ads for the blog, send me an email at <a className="gradient-text" href="mailto:hello@lindaikechukwu.com"> HELLO@LINDAIKECHUKWU.COM. </a></p>
                </div>
            </div>
        </Layout>
    )
}

export default AboutPage
